<nb-card style="width: auto;">
  <nb-card-body>
    <div class="col-2 date-range-box">
      <div class="filter-label">Select a time frame to download</div>
      <div class="status">
      <nz-range-picker
        class="form-control range-picker"
        [nzMode]="dateRangeSelectionText.toLowerCase()"
        [nzPlaceHolder]="'Select ' + dateRangeSelectionText + ' Range'"
        [(ngModel)]="selectedDateRange"
        [nzAllowClear]="false"
        [nzDisabledDate]="selectableDateRangeFn | async | defaultTo: noDateAllowed"
        (ngModelChange)="onDateChange($event)">
      </nz-range-picker>
        <span *ngIf="!isDownloadable">
          <nb-icon 
            class="scenario-item-alert"
            icon="alert-circle-outline" 
            status="danger"
            nbTooltip="To ensure optimal performance, please choose a date range within the past 3 years."
            nbTooltipIcon="alert-circle-outline"></nb-icon>
        </span>
      </div>
      <div class="button-container">
        <button class="plan-mgt-btn -save" nbButton (click)="submit()" status="primary" 
        [disabled]="!isDownloadable" style="--save-btn-color: {{ !isDownloadable ? '#c3c3c3' : '#426c9d' }};" >Download</button>
        <button class="plan-mgt-btn -cancel" (click)="dismiss()">Cancel</button>
      </div>
    </div>
  </nb-card-body>
</nb-card>