<div
  class="item"
  [class.highlighted]="isHighlighted"
  style="--highlighted-color: {{ highlightedColorTransparent(this.scenario?.color) }};
   --border-color: {{ this.scenario?.color }}"
  (click)="onHighlighScenario()"
  [ngClass]="{ 'zebra': shouldDisableInteractions() }"
>
  <div
    class="caption"
    #itemIdentifier
  >
    <cel-planning-scenario-badge
      [plan]="plan"
      [selectedPlan]="selectedPlan"
      [scenario]="scenario"
      [primary]="isPrimary"
      [isChangeColor]="'isChangeColor'"
    ></cel-planning-scenario-badge>
    <div class="title" 
      (click)="onHighlighScenario()"
      [nbPopover]="nbPopoverScenario"
      nbPopoverTrigger="hint"
      nbPopoverPlacement="bottom"
    >
      {{ this.scenario?.name }}
    </div>
    <div class="status">
      <div [class.loader]="isLoading"></div>
      <span *ngIf="isScenarioProcessing(scenario?.id)">
        <nb-icon *ngIf="isScenarioValidatingDRP(scenario?.id)" class="scenario-item-alert" icon="alert-circle-outline" status="warning"
          nbTooltip="Scenario is being validated before simulation" nbTooltipIcon="alert-circle-outline"></nb-icon>
        <nb-icon *ngIf="isScenarioTriggeringSimulation(scenario?.id)" class="scenario-item-alert" icon="alert-circle-outline" status="warning"
          nbTooltip="Preparing Scenario data for simulation" nbTooltipIcon="alert-circle-outline"></nb-icon>
        <nb-icon *ngIf="isScenarioSimulatingDRP(scenario?.id)" class="scenario-item-alert" icon="alert-circle-outline" status="warning"
          nbTooltip="Scenario is being processed" nbTooltipIcon="alert-circle-outline"></nb-icon>
      </span>
      <span *ngIf="isScenarioSimulationFailed(scenario?.id)">
        <nb-icon
          class="scenario-item-alert"
          icon="alert-circle-outline"
          status="danger"
          [nbTooltip]="simulationError || ''"
          nbTooltipIcon="alert-circle-outline"></nb-icon>
      </span>
    </div>
    <nb-icon class="scenario-item-alert" *ngIf="showEtlLoader" icon="alert-circle-outline"
      status="warning" nbTooltip="Etl task is still in progress" nbTooltipIcon="alert-circle-outline"></nb-icon>
  </div>
  <button
    *ngIf="!readonly"
    nbButton
    ghost
    size="small"
    shape="round"
    [nbPopover]="nbScenarioOptionPopover"
    [nbPopoverContext]="isPrimary"
    nbPopoverPlacement="bottom"
    #scenarioOptionPopover="nbPopover"
  >
    <nb-icon icon="more-vertical-outline"></nb-icon>
  </button>
</div>

<ng-template #nbPopoverScenario >
  <div class="nbPopoverScenario">
    <p><b>Scenario Name:</b> {{scenario?.name}} </p>
    <p><b>From Plan:</b> {{plan?.name || previousPlan }} </p>
    <p *ngIf="shouldDisableInteractions()" style="color: red;">
      <ng-container *ngIf="isScenarioBeingSimulated(); else editingMessage">
        <b>Scenario is being processed and cannot be edited</b>
      </ng-container>
      <ng-template #editingMessage>
        <b>{{ findEditingRemoteUser() }}</b> is editing this scenario
      </ng-template>
    </p>
  </div>
</ng-template>

<ng-template #nbScenarioOptionPopover let-data>
  <nb-list>
    <nb-list-item 
      *ngIf="isScenarioMarkableAsPrimary(scenario) && canManageScenario"
      class="scenario-btn -list-item"
      nbButton
      ghost
      (click)="onSetPrimary()"
      [ngClass]="{'disabled-style': shouldDisableInteractions()}"
    >
      <nb-icon
        class="scenario-btn -primary"
        [icon]="isPrimary ? 'primary' : 'non-primary'"
        pack="simcel-scenario-icons"
      ></nb-icon>
      <label class="scenario-btn -primary -label">Make primary</label>
    </nb-list-item>

    <nb-list-item
      *ngIf="isScenarioClonable(scenario) && canManageScenario"
      class="scenario-btn -list-item"
      nbButton
      ghost
      (click)="onClone()"
      [ngClass]="{'disabled-style': isMaxDisplayedCountReached()}"
    >
      <nb-icon class="scenario-btn -clone" icon="copy"></nb-icon>
      <label class="scenario-btn -clone -label">Clone</label>
    </nb-list-item>

    <nb-list-item *ngIf="!shouldDisableNetworkOption" class="scenario-btn -list-item" nbButton ghost (click)="onOpenUI()">
      <nb-icon class="scenario-btn" icon="activity-outline"></nb-icon>
      <label class="scenario-btn -label">Network</label>
    </nb-list-item>
    <nb-list-item
      *ngIf="isScenarioEditable(scenario) && canManageScenario"
      class="scenario-btn -list-item"
      nbButton
      ghost
      (click)="onEdit()"
      [ngClass]="{'disabled-style': shouldDisableInteractions()}"
    >
      <nb-icon class="scenario-btn -edit" icon="edit"></nb-icon>
      <label class="scenario-btn -edit -label">Edit</label>
    </nb-list-item>

    <nb-list-item
      *ngIf="isScenarioHideable(scenario)"
      status="warning"
      class="scenario-btn -list-item"
      nbButton
      ghost
      (click)="onHide()"
    >
      <nb-icon class="scenario-btn -hide" icon="close-outline"></nb-icon>
      <label class="scenario-btn -hide -label">Unload</label>
    </nb-list-item>
    <nb-list-item
      *ngIf="isScenarioDeletable(scenario) && canManageScenario"
      class="scenario-btn -list-item"
      nbButton
      ghost
      (click)="onDelete()"
      [ngClass]="{'disabled-style': shouldDisableInteractions()}"
    >
      <nb-icon class="scenario-btn -remove" icon="trash-2-outline"></nb-icon>
      <label class="scenario-btn -remove -label">Delete</label>
    </nb-list-item>
    <nb-list-item
      *ngIf="isScenarioDownloadable(scenario)"
      class="scenario-btn -list-item"
      nbButton
      ghost
      [nbPopover]="downloadMasterTemplate"
      [nbPopoverContext]="isPrimary"
      nbPopoverPlacement="bottom"
    >
      <nb-icon class="scenario-btn -download" icon="download-outline"></nb-icon>
      <label class="scenario-btn -download -label">Master Data</label>
    </nb-list-item>
    <nb-list-item
      *ngIf="isScenarioDownloadable(scenario)"
      class="scenario-btn -list-item"
      nbButton
      ghost
      [nbPopover]="downloadTransactionTemplate"
      [nbPopoverContext]="isPrimary"
      nbPopoverPlacement="bottom"
    >
      <nb-icon class="scenario-btn -download" icon="download-outline"></nb-icon>
      <label class="scenario-btn -download -label">Transactional Data </label>
    </nb-list-item>
    <nb-list-item 
      *ngIf="scenario && canManageScenario && isScenarioAllowValidate(scenario.id) && !isScenarioProcessing(scenario.id)"
      class="scenario-btn -list-item"
      nbButton
      ghost
      (click)="onValidateSITChanges(scenario.id)"
      [ngClass]="{'disabled-style': shouldDisableInteractions()}"
    >
      <nb-icon class="scenario-btn" icon="activity-outline"></nb-icon>
      <label class="scenario-btn -label">Run simulation</label>
    </nb-list-item>
  </nb-list>
</ng-template>

<ng-template #downloadMasterTemplate let-data>
  <nb-list>
    <nb-list-item *ngIf="canDownloadMasterData(scenario)" class="scenario-btn -list-item" nbButton ghost
      (click)="onDownload('Facility')">
      <label class="scenario-btn -download -label">Facility Master</label>
    </nb-list-item>
    <nb-list-item *ngIf="canDownloadMasterData(scenario)" class="scenario-btn -list-item" nbButton ghost
      (click)="onDownload('Product')">
      <label class="scenario-btn -download -label">Product Master</label>
    </nb-list-item>
    <nb-list-item *ngIf="canDownloadMasterData(scenario)" class="scenario-btn -list-item" nbButton ghost
      (click)="onDownload('Product Value')">
      <label class="scenario-btn -download -label">Product Value Master</label>
    </nb-list-item>
    <nb-list-item *ngIf="canDownloadMasterData(scenario)" class="scenario-btn -list-item" nbButton ghost
      (click)="onDownload('DRP Physical Network')">
      <label class="scenario-btn -download -label">DRP Physical Network</label>
    </nb-list-item>
    <nb-list-item *ngIf="canDownloadMasterData(scenario)" class="scenario-btn -list-item" nbButton ghost
      (click)="onDownload('Physical Network')">
      <label class="scenario-btn -download -label">Physical Network</label>
    </nb-list-item>
    <nb-list-item class="scenario-btn -list-item" nbButton ghost
      (click)="onDownload('Currency Exchange Rate')">
      <label class="scenario-btn -download -label">Currency Exchange Rate</label>
    </nb-list-item>
    <nb-list-item *ngIf="canDownloadCostMaster()" class="scenario-btn -list-item" nbButton ghost
      (click)="onDownload('Cost Master')">
      <label class="scenario-btn -download -label">Cost Master</label>
    </nb-list-item>
    <nb-list-item *ngIf="canDownloadSalesReturn()" class="scenario-btn -list-item" nbButton ghost
      (click)="onDownload('Sales Return')">
      <label class="scenario-btn -download -label">Sales Return</label>
    </nb-list-item>
    <nb-list-item *ngIf="canDownloadSITProvision()" class="scenario-btn -list-item" nbButton ghost
      (click)="onDownload('SIT Provision')">
      <label class="scenario-btn -download -label">SIT Provision</label>
    </nb-list-item>
  </nb-list>
</ng-template>

<ng-template #downloadTransactionTemplate let-data>
  <nb-list>
    <nb-list-item class="scenario-btn -list-item" nbButton ghost (click)="onDownload('Demand')">
      <label class="scenario-btn -download -label">Demand</label>
    </nb-list-item>
    <nb-list-item *ngIf="canDownloadUnconstrainedDRPData(scenario)" class="scenario-btn -list-item" nbButton ghost
      (click)="onDownload('Unassigned Sales Out')">
      <label class="scenario-btn -download -label">Unassigned Sales Out</label>
    </nb-list-item>
    <nb-list-item *ngIf="canDownloadScenarioOutput(scenario)" class="scenario-btn -list-item" nbButton ghost
      (click)="onDownload('Simulation Sales In')">
      <label class="scenario-btn -download -label">Simulation Sales In</label>
    </nb-list-item>
    <nb-list-item *ngIf="canDownloadSupplyPlan(scenario)" class="scenario-btn -list-item" nbButton ghost
      (click)="onDownload('Selected Supply Plan')">
      <label class="scenario-btn -download -label">Selected Supply Plan</label>
    </nb-list-item>
    <nb-list-item *ngIf="canDownloadScenarioOutput(scenario)" class="scenario-btn -list-item" nbButton ghost
      (click)="onDownload('Demand Fulfillment Metrics')">
      <label class="scenario-btn -download -label">Demand Fulfillment Metrics</label>
    </nb-list-item>
    <nb-list-item *ngIf="canDownloadScenarioOutput(scenario)" class="scenario-btn -list-item" nbButton ghost
      (click)="onDownload('Detailed Inventory Metrics')">
      <label class="scenario-btn -download -label">Detailed Inventory Metrics</label>
    </nb-list-item>
    <nb-list-item *ngIf="canDownloadScenarioOutput(scenario)" class="scenario-btn -list-item" nbButton ghost
      (click)="onDownload('Production Outputs')">
      <label class="scenario-btn -download -label">Production Outputs</label>
    </nb-list-item>
    <nb-list-item *ngIf="canDownloadScenarioOutput(scenario)" class="scenario-btn -list-item" nbButton ghost
      (click)="onDownload('Atomic KPIs')">
      <label class="scenario-btn -download -label">Atomic KPIs</label>
    </nb-list-item>
    <nb-list-item *ngIf="canDownloadUnconstrainedDRPData(scenario)" class="scenario-btn -list-item" nbButton ghost
      (click)="onDownload('Unconstrained DRP')">
      <label class="scenario-btn -download -label">Unconstrained DRP Data</label>
    </nb-list-item>
    <nb-list-item *ngIf="canDownloadScenarioOutput(scenario)" class="scenario-btn -list-item" nbButton ghost
      (click)="onDownload('Constrained DRP')">
      <label class="scenario-btn -download -label">Constrained DRP Data</label>
    </nb-list-item>
  </nb-list>
</ng-template>
