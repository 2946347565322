import { SimpleDateRange } from "@/app/@core/interfaces/common/date-range";
import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { NbDialogRef } from "@nebular/theme";

const DOWNLOAD_YEARS_LIMIT = 3

@Component({
    selector: 'cel-scenario-download-dialog',
    templateUrl: './scenario-download-dialog.component.html',
    styleUrls: ['./scenario-download-dialog.component.scss'],
})

export class ScenarioDownloadDialogComponent implements OnInit, OnChanges {
    @Input() dateRange: SimpleDateRange = { start: new Date().toISOString(), end: new Date().toISOString() };
    @Input() dateRangeSelectionText: string = "Date";
    @Input() selectableDateRangeFn: any;
    @Input() onSubmit: (ref: NbDialogRef<ScenarioDownloadDialogComponent>) => void = () => { };
    @Input() onCancel: (ref: NbDialogRef<ScenarioDownloadDialogComponent>) => void = () => { };
    selectedDateRange: (Date | undefined)[] = [];
    noDateAllowed = (_: Date) => false;
    isDownloadable: boolean = false

    constructor(protected ref: NbDialogRef<ScenarioDownloadDialogComponent>) { }
    ngOnChanges(changes: SimpleChanges): void {
        if ('dateRange' in changes && this.dateRange && this.dateRange.start && this.dateRange.end) {
            const startDate = new Date(this.dateRange.start);
            const endDate = new Date(this.dateRange.end);
            this.selectedDateRange = [startDate, endDate];

            const yearsGap = this.calculateYearGap(startDate, endDate)
            this.isDownloadable = yearsGap <= DOWNLOAD_YEARS_LIMIT ? true : false

        }
    }

    ngOnInit(): void {
        this.selectedDateRange = [new Date(this.dateRange.start), new Date(this.dateRange.end)]
        const yearsGap = this.calculateYearGap(this.selectedDateRange[0]!, this.selectedDateRange[1]!)
        this.isDownloadable = yearsGap <= DOWNLOAD_YEARS_LIMIT ? true : false
    }

    dismiss() {
        this.onCancel(this.ref);
        this.ref.close();
    }

    submit() {
        this.onSubmit(this.ref);
        this.ref.close({
            start: this.selectedDateRange[0]?.toISOString(),
            end: this.selectedDateRange[1]?.toISOString()
        });
    }

    onDateChange(result: (Date | null)[]): void {
        let startDate: Date | undefined = this.selectedDateRange[0];
        let endDate: Date | undefined = this.selectedDateRange[1];

        if (result.length >= 1 && result[0] != null) {
            startDate = new Date(result[0]);

            if (result.length === 2 && result[1] != null) {
                endDate = new Date(result[1]);
            }
        }
        this.selectedDateRange = [startDate, endDate];

        const yearsGap = this.calculateYearGap(startDate!, endDate!)
        this.isDownloadable = yearsGap <= DOWNLOAD_YEARS_LIMIT ? true : false
    }

    calculateYearGap(startDate: Date, endDate: Date): number {
        let years = endDate.getFullYear() - startDate.getFullYear();
        let months = endDate.getMonth() - startDate.getMonth();
        let days = endDate.getDate() - startDate.getDate();
    
        // Adjust if the month and day of endDate is before the month and day of startDate
        if (months < 0 || (months === 0 && days < 0)) {
            years--;
        }
    
        return years;
    }
}