import { createSelector } from '@ngrx/store';
import { addMonths, subMonths, addDays, isAfter, isBefore, max, min, setDate } from 'date-fns';

import { select_latestPlan, select_sortedPlan } from '@/store/plan/plan.selectors';
import {
  select_pendingForecastExplorerTasks,
  select_selectedDateRangeRaw,
  select_selectedDateRangeForecastRaw,
  select_selectedPlanId,
  select_selectedSegment,
  select_pendingForecastReportTasks,
  select_isCheckingForecastReportTask,
} from './layout.baseSelectors';
import { intersection, SimpleDateRange } from '@/app/@core/interfaces/common/date-range';
import { refId } from '@/app/@core/interfaces/common/mongo';
import { Plan, PlanFlag } from '@/app/@core/interfaces/business/plan';
import { IGetScenarioDemandParams } from '@/app/@core/entity/demand.service';
import { parseYYYYMMDD, formatYYYYMMDD, calculateDateRangeBasedOnEndDate } from '@/utils/calendar';
import { LOCALSTORAGE_KEY_SELECTED_PLAN_ID } from '@/app/pages/explorer/shared/explorer.utils';
import { select_soonestActual, select_latestActual } from '@/store/actual/actual.selectors';
import { select_plansetting } from '@/store/plan-setting/planSetting.selectors';

export const select_selectedPlan = createSelector(
  select_sortedPlan,
  select_selectedPlanId,
  (plans, selectedPlanId): Plan | undefined => {
    const previousSelectedPlanId = localStorage.getItem(LOCALSTORAGE_KEY_SELECTED_PLAN_ID);
    const planId = selectedPlanId || previousSelectedPlanId;
    return plans.find((p) => p.id === planId) || plans[0];
  }
);

export const select_selectableDateRange = createSelector(
  select_selectedPlan,
  select_soonestActual,
  (selectedPlan, soonestActual): { min: Date; max: Date } => {
    const defaultRange = { min: new Date(1900, 1, 1), max: new Date(3000, 1, 1) };

    if (!selectedPlan || !soonestActual) return defaultRange;

    return {
      min: new Date(soonestActual.actualStartDate),
      max: parseYYYYMMDD(selectedPlan.defaultPlanDisplayEndDate),
    };
  },
);

export const select_disabledDateRangeFn = createSelector(
  select_selectedPlan,
  select_soonestActual,
  (selectedPlan, soonestActual): ((current: Date) => boolean) => {
    if (!selectedPlan || !soonestActual) return () => false;

    const plan_end = parseYYYYMMDD(selectedPlan.futurePlanEndDate);
    const actual_start = new Date(soonestActual.actualStartDate);
    const startOfMonth = new Date(actual_start.getFullYear(), actual_start.getMonth(), 1);
    const endOfMonth = new Date(plan_end.getFullYear(), plan_end.getMonth() + 1, 0);

    return (date: Date) => isBefore(date, startOfMonth) || isAfter(date, endOfMonth);
  },
);

export const select_disabledDateRangeForecastFn = createSelector(
  select_soonestActual,
  select_latestActual,
  (soonestActual, latestActual): ((current: Date) => boolean) => {
    if (!latestActual) return () => false;

    const actual_start = new Date(soonestActual.actualStartDate);
    const actual_end = new Date(latestActual.actualEndDate);
    const startOfMonth = new Date(actual_start.getFullYear(), actual_start.getMonth(), 1);
    const endOfMonth = new Date(actual_end.getFullYear(), actual_end.getMonth() + 1, 0);

    return (date: Date) => isBefore(date, startOfMonth) || isAfter(date, endOfMonth);
  },
);

export const select_presettedDateRanges = createSelector(
  select_selectedPlan,
  select_soonestActual,
  (selectedPlan, soonestActual): Record<string, Array<Date>> => {
    if (!selectedPlan || !soonestActual) return {};

    const { start, end } = calculateDateRangeBasedOnEndDate(selectedPlan) as SimpleDateRange;
    const defaultStart = parseYYYYMMDD(start);
    const defaultEnd = parseYYYYMMDD(end);

    const plan_start = parseYYYYMMDD(selectedPlan.defaultPlanDisplayStartDate);
    const plan_end = parseYYYYMMDD(selectedPlan.defaultPlanDisplayEndDate);

    const future_start = parseYYYYMMDD(selectedPlan.futurePlanStartDate);
    const future_end = parseYYYYMMDD(selectedPlan.futurePlanEndDate);
    const actual_start = new Date(soonestActual.actualStartDate);

    // const firstMonth_end = addMonths(plan_start, 1);
    const first3Months_end = addDays(addMonths(plan_start, 3), -1);
    // const lastMonth_start = addMonths(plan_end, -1);
    const last3Months_start = addMonths(addDays(plan_end, 1), -3);

    return {
      Default: [defaultStart, defaultEnd],
      All: [actual_start, future_end],
      // 'First Month': [plan_start, min([firstMonth_end, plan_end])],
      'First 3 Months': [plan_start, min([first3Months_end, plan_end])],
      // 'Last Month': [max([lastMonth_start, plan_end, plan_start])],
      'Last 3 Months': [max([last3Months_start, plan_start]), plan_end],
    };
  },
);

export const select_presettedDateRangesForecast = createSelector(
  select_selectedPlan,
  select_latestActual,
  (selectedPlan, latestActual): Record<string, Array<Date>> => {
    if (!selectedPlan || !latestActual) return {};

    const plan_end = parseYYYYMMDD(selectedPlan.defaultPlanDisplayEndDate);
    const future_end = parseYYYYMMDD(selectedPlan.futurePlanEndDate);
    const actual_start = new Date(latestActual.actualStartDate);
    const actual_end = new Date(latestActual.actualEndDate);

    // const firstMonth_end = addMonths(plan_start, 1);
    const first3Months_end = addDays(addMonths(actual_start, 3), -1);
    // const lastMonth_start = addMonths(plan_end, -1);
    const last3Months_start = addMonths(addDays(actual_end, 1), -3);

    return {
      Default: [actual_start, actual_end],
      All: [actual_start, future_end],
      // 'First Month': [plan_start, min([firstMonth_end, plan_end])],
      'First 3 Months': [actual_start, min([first3Months_end, plan_end])],
      // 'Last Month': [max([lastMonth_start, plan_end, plan_start])],
      'Last 3 Months': [max([last3Months_start, actual_start]), actual_end],
    };
  },
);

export const select_selectedPlanDefaultDateRange = createSelector(
  select_selectedPlan,
  calculateDateRangeBasedOnEndDate
);

export const select_selectedDefaultDateRangeForecast = createSelector(
  select_latestActual,
  (latestActual): SimpleDateRange | undefined => {
    if (!latestActual) return;

    const actualEndDate = setDate(new Date(latestActual.actualEndDate), 31)

    const startDate = formatYYYYMMDD(setDate(subMonths(actualEndDate, 11), 1))
    const endDate = formatYYYYMMDD(actualEndDate)

    return {
      start: startDate,
      end: endDate,
    }
  }
);

export const select_selectedDateRange = createSelector(
  select_plansetting,
  select_selectedDateRangeRaw,
  select_selectedPlanDefaultDateRange,
  (setting, selectedDateRange, selectedPlanDefaultDateRange) => {
    // Return 'selectedDateRange' or 'selectedPlanDefaultDateRange' 
    // if 'setting' is falsy, or 'displayedStartDate'/'displayedEndDate' is falsy or empty
    if (!setting || 
        !setting.displayedStartDate || setting.displayedStartDate === '' || 
        !setting.displayedEndDate || setting.displayedEndDate === '') {
      return selectedDateRange || selectedPlanDefaultDateRange;
    }

    // Return the date range from 'setting' if all conditions above are not met
    return { start: setting.displayedStartDate, end: setting.displayedEndDate };
  }
);

export const select_selectedDateRangeForecast = createSelector(
  select_selectedDateRangeForecastRaw,
  select_selectedDefaultDateRangeForecast,
  (selectedDateRangeForecast, selectedDefaultDateRangeForecast) => {
    return selectedDateRangeForecast || selectedDefaultDateRangeForecast
  }
);

export const select_selectDateRangeDownload = createSelector(
  select_selectedPlan,
  select_soonestActual,
  select_latestActual,
  (selectedPlan, soonestActual, latestActual) => {
    if (!selectedPlan || !soonestActual || !latestActual) return

    if (selectedPlan?.flags?.includes(PlanFlag.ACTUAL)) {
      return {
        start: new Date(soonestActual.actualStartDate).toISOString(),
        end: new Date(latestActual.actualEndDate).toISOString()
      }
    }

    return { start: selectedPlan.futurePlanStartDate, end: selectedPlan.futurePlanEndDate }
  }
);

export const select_previousPlan = createSelector(
  select_sortedPlan,
  select_selectedPlan,
  (plans, selectedPlan) => {
    if (!selectedPlan) {
      return null;
    }

    return plans.find((p) => p.id === refId(selectedPlan.previousPlanCycle)) || null;
  },
);

export const select_editingEventVersionDemandsParams = createSelector(
  select_selectedPlan,
  select_selectedSegment,
  select_selectedDateRange,
  (selectedPlan, selectedSegment, selectedDateRange): IGetScenarioDemandParams | undefined => {
    if (!selectedPlan) return;

    return {
      database: '',
      segment: selectedSegment,
      dateRange: intersection(selectedDateRange, {
        start: selectedPlan.futurePlanStartDate,
        end: selectedPlan.futurePlanEndDate,
      }),
      eventVersionIds: [],
      segmentDatabase: '',
      planId: selectedPlan.id,
      companyId: selectedPlan.company,
      workspaceId: selectedPlan.workspace.id,
      forceRun: false,
    };
  },
);

export const select_hasPendingForecastExplorerTasks = createSelector(
  select_pendingForecastExplorerTasks,
  (pendingForecastExplorerTasks) => {
    if (pendingForecastExplorerTasks.length > 0) {
      return true;
    }

    return false;
  }
)

export const select_hasPendingForecastReportTasks = createSelector(
  select_pendingForecastReportTasks,
  select_isCheckingForecastReportTask,
  (pendingForecastReportTasks, isCheckingForecastReportTask) => {
    if (pendingForecastReportTasks.length > 0 || isCheckingForecastReportTask) {
      return true;
    }

    return false;
  }
)
