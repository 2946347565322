import { DateAggregationOption } from '@/app/pages/explorer/planning-explorer/widgets/timeseries/timeseries.constants';
import { Injectable } from '@angular/core';
import { HttpService } from '../backend/common/api/http.service';
import { Segment } from '../interfaces/business/segment';
import { SimpleDateRange } from '../interfaces/common/date-range';

export interface IGetKpisParams {
  segment?: Segment;
  dateRange?: SimpleDateRange;
  planId: string;
  scenarios: Array<string>;
  interval: DateAggregationOption;
  kpisByDate: boolean;
  currency?: string;
}

export interface IGetKpisLoadedParams extends IGetKpisParams {
  displayedScenarios?: string[];
}

export interface IGetKpisByDateParams extends IGetKpisParams {
}

export interface IGetAtomicKpisParams extends IGetKpisParams {
  groupingColumns: string[];
  uom?: string;
  kpiName: string;
}

export interface IGetAtomicKpisLoadedParams extends IGetAtomicKpisParams {
  displayedScenarios?: string[];
}

export enum AtomicKPIs {
  DemandValue = 'DemandValue',
  TradeExpenses = 'TradeExpenses',
  NetSalesValue = 'NetSalesValue',
  GrossSalesValue = 'GrossSalesValue',
  OrderQuantity = 'OrderQuantity',
  DispatchQuantity = 'DispatchQuantity',
  COGS = 'COGS',
  DistributionCost = 'DistributionCost',
  HandlingCost = 'HandlingCost',
  StorageCost = 'StorageCost',
  TransferCost = 'TransferCost',
  DistributorCOGS = 'DistributorCOGS',
  DistributorDistributionCost = 'DistributorDistributionCost',
  DistributorTransferCost = 'DistributorTransferCost',
  DistributorStorageCost = 'DistributorStorageCost',
  DistributorHandlingCost = 'DistributorHandlingCost',
  DistributorNetSalesValue = 'DistributorNetSalesValue',
  CO2 = 'CO2',
  InboundCO2 = 'InboundCO2',
  StorageCO2 = 'StorageCO2',
  OutboundCO2 = 'OutboundCO2',
  UnconstrainedCLS = 'UnconstrainedCLS',
  ConstrainedCLS = 'ConstrainedCLS',
  UnconstrainedSalesOut = 'UnconstrainedSalesOut',
  ConstrainedSalesOut = 'ConstrainedSalesOut',
  ConstrainedSalesOutGSV = 'ConstrainedSalesOutGSV',
  UnconstrainedSalesOutGSV = 'UnconstrainedSalesOutGSV',
  UnconstrainedCLSGSV = 'UnconstrainedCLSGSV',
  ConstrainedCLSGSV = 'ConstrainedCLSGSV',
  UnconstrainedCLSGSVValuation = 'UnconstrainedCLSGSVValuation',
  ConstrainedCLSGSVValuation = 'ConstrainedCLSGSVValuation',
  SGACost = 'SGACost',
  FIEDemandValue = 'FIEDemandValue',
  GrossFIESales = 'GrossFIESales',
  FIETradeExpenses = 'FIETradeExpenses',
  Transport = 'Transport',
  StorageAndHandling = 'StorageAndHandling',
  Promotion = 'Promotion',
  MedicalAffairs = 'MedicalAffairs',
  Administration = 'Administration',
  NetFIESales = 'NetFIESales',
  GrossProfit = 'GrossProfit',
  LocalDemandValue = 'LocalDemandValue',
  LocalDemandVolume = 'LocalDemandVolume',
  GrossLocalSales = 'GrossLocalSales',
  LocalSalesVolume = 'LocalSalesVolume',
  DistributorLocalDemandValue = 'DistributorLocalDemandValue',
  DistributorLocalDemandVolume = 'DistributorLocalDemandVolume',
  DistributorGrossLocalSales = 'DistributorGrossLocalSales',
  DistributorLocalSalesVolume = 'DistributorLocalSalesVolume',
  LocalTradeExpenses = 'LocalTradeExpenses',
  FIEDemandVolume = 'FIEDemandVolume',
  FIESalesVolume = 'FIESalesVolume',
  LocalCaseFillRate = 'LocalCaseFillRate',
  FIECaseFillRate = 'FIECaseFillRate',
  NetLocalSales = 'NetLocalSales',
  COGSPercent = 'COGSPercent',
  GrossMargin = 'GrossMargin',
  SupplyChainCost = 'SupplyChainCost',
  DPM = 'DPM',
  DPMPercent = 'DPMPercent',
  DPMA = 'DPMA',
  DPMAPercent = 'DPMAPercent',
  EBITPercent = 'EBITPercent',
}

export interface IGetSpChartDataParams extends IGetKpisParams {
  interval: DateAggregationOption;
  kpisByDate: boolean;
}

export interface IKPIMatrixParams {
  startDate: string;
  endDate: string;
  segment: Segment;
  atomickpisDB: string;
  masterInputDatabase: string;
}

@Injectable()
export class KpisService {
  constructor(private readonly httpService: HttpService) { }

  getKpis(params: IGetKpisParams) {
    return this.httpService.post('kpis', params);
  }

  getSpChart(params: IGetSpChartDataParams) {
    return this.httpService.post('kpis/chart', params);
  }

  getAtomicKpis(params: IGetAtomicKpisParams) {
    return this.httpService.post('kpis/atomic', params);
  }

  getKPIMatrix(params: IKPIMatrixParams) {
    return this.httpService.postDataScienceAPI('kpi-matrix', params);
  }

  getKPIMap(params: IKPIMatrixParams) {
    return this.httpService.postDataScienceAPI('kpi-map', params);
  }
}
