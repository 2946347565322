import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';

import { Scenario } from '../interfaces/business/scenario';
import { HttpService } from '../backend/common/api/http.service';
import { Observable, lastValueFrom, withLatestFrom } from 'rxjs';
import { Workspace } from '../interfaces/common/workspace';
import { Plan } from '../interfaces/business/plan';
import { SimpleDateRange } from '../interfaces/common/date-range';

@Injectable({ providedIn: 'root' })
export class ScenarioService extends EntityCollectionServiceBase<Scenario> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory,
    private readonly api: HttpService) {
    super('explorer/scenarios', serviceElementsFactory);
  }

  async downloadScenario(scenario: Scenario, plan: Plan, downloadType: string, dateRange?: SimpleDateRange) {
    const csvContent = await lastValueFrom(this.api.post(`${this.entityName}/download-scenario`, {
      scenarioId: scenario.id.toString(), workspaceId: scenario.workspace, downloadType: downloadType, dateRange: dateRange
    }, { responseType: 'json' }));

    var csvData = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    var csvURL = window.URL.createObjectURL(csvData);

    var tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', plan.name + ' - ' + scenario.name + ' - ' + downloadType + '.csv');
    tempLink.click();

    return csvContent
  }

  async  updatePrimaryScenario(scenario:Scenario) {
    await lastValueFrom(this.api.post(`${this.entityName}/update-primary-scenario`,{
      scenarioId: scenario.id
    },{ responseType: 'json' }))
  }
}
