/**
 * Defines all actual info.
 */
export declare interface Actual {
  id: string;
  workspaceId: string;
  actualStartDate: Date;
  actualEndDate: Date;
  actualDRPStartDate: Date;
  actualDRPEndDate: Date;
  actualInputDatabase: string;
  actualOutputDatabase: string;
  drpDatabse: string;
}

export function sortActualsByStartDate(actuals: Actual[]): Actual[] {
  let salesOutActuals = actuals.filter((a) => a.actualStartDate)

  return (salesOutActuals || []).sort((a, b) => {
    return new Date(a.actualStartDate).getTime() - new Date(b.actualStartDate).getTime()
  });
}

export function sortActualsByEndDate(actuals: Actual[]): Actual[] {
  let salesOutActuals = actuals.filter((a) => a.actualStartDate)

  return (salesOutActuals || []).sort((a, b) => {
    return new Date(b.actualEndDate).getTime() - new Date(a.actualEndDate).getTime()
  });
}

export function sortActualsByDRPStartDate(actuals: Actual[]): Actual[] {
  let drpActuals = actuals.filter((a) => a.actualDRPStartDate)

  return (drpActuals || []).sort((a, b) => {
    return new Date(a.actualStartDate).getTime() - new Date(b.actualStartDate).getTime()
  });
}

export function sortActualsByDRPEndDate(actuals: Actual[]): Actual[] {
  let drpActuals = actuals.filter((a) => a.actualDRPStartDate)

  return (drpActuals || []).sort((a, b) => {
    return new Date(b.actualEndDate).getTime() - new Date(a.actualEndDate).getTime()
  });
}

